*[role='button'] {
  cursor: pointer;
}

.clearfix {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

/* Text Helpers */

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

/* Table Helpers */

.table-striped table > tbody > tr:nth-of-type(2n) {
  background-color: @autofocus-light-blue;
}

/* List Helpers */

.list-unstyled {
  list-style-type: none;
  .pl-0;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

.flex {
  display: flex;
}