@import (reference) '../index.less';
@import (reference) './colorPalette.less';

.ant-select {
  width: 100%;
}

/* .ant-modal {
  width: 60% !important;
  min-width: 400px !important;
  max-width: 90% !important;
} */

.ant-form-item-label {
  font-weight: bold;
}

.ant-layout-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: @ipHeaderHeight !important;
  line-height: @ipHeaderHeight !important;
  background: @dng-dark-blue !important;
}

.ant-layout {
  background-color: initial;

  .ant-layout-sider{
    &:not(.workspace-menu) {
      background-color: #F5F7FA;
    }    
  }

  .ant-layout-sider-light{
    &:not(.workspace-menu) {
      background-color: @dng-white !important;
    }
  }
}

.ant-dropdown-menu-item-selected,
.ant-menu-item-selected{
  background-color: #daf2f2 !important;
  color: rgba(0,0,0,.85) !important;
}

.ant-dropdown-menu-item,
.ant-menu-item {
  &.ant-dropdown-menu-item-selected,
  &.ant-menu-item-selected {
    border-right: 3px solid @dng-dark-blue !important;
  }
}
.ant-layout-content {
  // No idea why, but without `min-height: unset` we occasionally get a scroll bar on full-height sections.
  min-height: unset;
}

.ant-typography {
  /* Extends Typography with primary, info and white */
  &.ant-typography-primary {
    color: @dng-dark-blue !important;
  }

  &.ant-typography-info {
    color: @dng-cyan !important;
  }

  &.ant-typography-white {
    color: @dng-white !important;
  }
}

.ant-card-grid {
  box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, inset 1px 0 0 0 #f0f0f0, inset 0 1px 0 0 #f0f0f0 !important;
  border-radius: 2px !important;
}

.ant-breadcrumb-separator {
  color: @dng-white !important;
}

.ant-breadcrumb {
  color: @dng-white !important;

  .search-autocomplete-visible {
    .anticon {
      font-size: 18px !important;
      vertical-align: -3.5px !important;
      color: rgba(0, 0, 0, 0.3) !important;
    }
  }
  
  .anticon {
    font-size: 18px !important;
    vertical-align: -3.5px !important;
    color: @dng-white !important;
  }

  a {
    color: @dng-white;
  }
}

.ant-btn-link {
  &:not(.ant-btn-dangerous) {
    color: #409ec9 !important;

    :hover {
      color: #125e73 !important;
    }  
  }  
}

/**
 * New Button types
 */

 // Link variants mixin
.btn-link-variant(@color) {
  //.button-variant-other(@color, transparent, transparent);
  box-shadow: none;
  // &:hover,
  // &:focus {
  //   .button-color(~`colorPalette('@{color}', 5) `; transparent; transparent);
  // }
  // &:active {
  //   .button-color(~`colorPalette('@{color}', 7) `; transparent; transparent);
  // }
  //.button-disabled(@disabled-color; transparent; transparent);
}

.ant-btn {
  box-shadow: none;
  &.ant-btn-outline-bottom {
    &:not([disabled]) {
      border-color: @primary-color-reserve;
    }
  }

  &.ant-btn-link-success {
    .btn-link-variant(@success-color);
  }

  &.ant-btn-link-warning {
    .btn-link-variant(@warning-color);
  }

  &.ant-btn-link-info {
    .btn-link-variant(@info-color);
  }

  &.ant-btn-link-white {
    .btn-link-variant(@gray-2);
  }

  &.ant-btn-link-favorite {
    .btn-link-variant(@dng-yellow);
  }

  &.ant-btn-text-secondary {
    //.button-variant-other(@text-color-secondary, transparent, transparent);
    box-shadow: none;
    &:hover,
    &:focus {
      color: @text-color-secondary;
      background: transparent;
      border-color: transparent;
    }

    &:active {
      color: @text-color-secondary;
      background: transparent;
      border-color: transparent;
    }

    //.button-disabled(@disabled-color; transparent; transparent);
  }

  //&.ant-btn-info {
  //  .button-variant-primary(@btn-danger-color, @info-color);
  //}
  //
  //&.ant-btn-warning {
  //  .button-variant-primary(@btn-danger-color, @warning-color);
  //}
  //
  //&.ant-btn-success {
  //  .button-variant-primary(@btn-danger-color, @success-color);
  //}
  
}

/**
 * New Button type for radio: "outline-bottom". eg
 * <Radio.Group buttonStyle="outline-bottom">
 *   <Radio.Button value="foo">Foo</Radio.Button>
 * </Radio.Group>
 */
.ant-radio-group.ant-radio-group-outline-bottom > .ant-radio-button-wrapper {
  &::before {
    width: 0;
  }

  &.ant-radio-button-wrapper-checked {
    font-weight: bold;
    border-left-width: 1px;
  }
}

/* Fix Spin width */
.ant-spin-nested-loading {
  width: 100%;
}

.ant-switch.ip-pastel-switch {
  background-color: @red-4;
}
.ant-switch-checked.ip-pastel-switch {
  background-color: @green-4;
}

.ant-menu {
  .anticon {
    font-size: 18px !important;
  }  
}

.ant-menu-submenu-title {
  padding: 0 !important;  
}


.ant-menu-dark.ant-menu-submenu>.ant-menu {
  background-color: @dng-dark-blue !important;
  color: hsla(0,0%,100%,.9) !important;
}

.ant-modal-wrap {
  z-index: 1300 !important;
}

.ant-table-cell {
  z-index: 2 !important;
}
.ant-drawer-body {
  .ps__rail-y {
    display: none !important;
  }
}