/**
 * Generate classes to include margin or padding loosely based on Bootstrap
 * https://getbootstrap.com/docs/4.3/utilities/spacing/
 * This will generate classes for padding and margin like .{type}{direction}-{size}
 * where type is 'p' (padding) or 'm' (margin)
 * direction is 't' (top), 'b' (bottom), 'l' (left), 'r' (right),
 *              'h' (horizontal = left + right) or 'v' (vertical = top + bottom)
 * and size is 0, xs, sm, md, lg
 *
 * e.g. .pt-md => padding top medium
 *      .mv-lg => margin vertical large
 */

@directions: {
  @l: left;
  @r: right;
  @t: top;
  @b: bottom;
};

@sizes: {
  @0: 0;
  @1: @padding-xs / 2; // 8px
  @2: @padding-xs; // 8px
  @3: @padding-sm; // 12px
  @4: @padding-md; // 16px
  @5: @padding-lg; // 24px
  @6: @padding-lg * 2; // 48px
};

@sizesNames: {
  @0: 0;
  @1: xxs;
  @2: xs;
  @3: sm;
  @4: md;
  @5: lg;
  @6: xl;
};

.generate-spaces(@direction, @i: 0) when (@i =< 6) {
  @dir: @directions[@@direction];
  @sizeName: @sizesNames[@@i];

  .p@{direction}-@{sizeName} {
    padding-@{dir}: @sizes[@@i];
  }

  .m@{direction}-@{sizeName} {
    margin-@{dir}: @sizes[@@i];
  }

  .generate-spaces(@direction, @i + 1);
}

.generate-spaces(t);
.generate-spaces(r);
.generate-spaces(b);
.generate-spaces(l);

.generate-dual(@name, @dir1, @dir2, @i: 0) when (@i =< 6) {
  @sizeName: @sizesNames[@@i];

  .p@{name}-@{sizeName} {
    padding-@{dir1}: @sizes[@@i];
    padding-@{dir2}: @sizes[@@i];
  }

  .m@{name}-@{sizeName} {
    margin-@{dir1}: @sizes[@@i];
    margin-@{dir2}: @sizes[@@i];
  }

  .generate-dual(@name, @dir1, @dir2, @i + 1);
}

.generate-dual(h, left, right);
.generate-dual(v, top, bottom);

.generate-full(@i: 0) when (@i =< 6) {
  @sizeName: @sizesNames[@@i];

  .p-@{sizeName} {
    padding: @sizes[@@i];
  }

  .m-@{sizeName} {
    margin: @sizes[@@i];
  }

  .generate-full(@i + 1);
}

.generate-full();
